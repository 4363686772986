// Imports
import Flickity from 'flickity-fade';
import $ from 'jquery';
import lity from 'lity';
import lozad from 'lozad';
import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout';
// import MicroModal from "micromodal"; // es6 module

const heroComponent = (() => {
	// Vars
	const frontPageHero = document.querySelector('.front-page-hero');
	const heroSlider = document.querySelector('.b-hero__slider');
	const slides = document.querySelectorAll('.b-hero__bg');
	const sliderNav = [...document.querySelectorAll('.b-hero__slider-nav li')];
	const prevButton = document.querySelector(
		'.b-hero__slider-nav__arrows-prev'
	);
	const nextButton = document.querySelector(
		'.b-hero__slider-nav__arrows-next'
	);
	const htmlRoot = document.getElementsByTagName('html')[0];
	const threshold = 130;

	const initialiseCompontent = () => {
		__textAnimation();
		__addClassToHTML();
		__videoLightbox();

		if (slides.length > 1) {
			const sliderOptions = {
				pageDots: false,
				prevNextButtons: false,
				draggable: false,
				autoPlay: 5000,
				fade: true,
				pauseAutoPlayOnHover: false,
			};
			const slider = new Flickity(heroSlider, sliderOptions);
			const background = document.querySelectorAll('.b-hero__bg');

			__sliderButtons(slider);
			__sliderArrows(slider);

			slider.on('change', (i) => {
				__slideContrast(background, threshold, i);

				// Iterate Through all nav links and remove active class
				sliderNav.forEach((el) => {
					if (el.classList.contains('is-active')) {
						el.classList.remove('is-active');
					}
				});

				// Add Active class to this slide navigation link
				sliderNav[i].classList.add('is-active');

				// Disable Previous Button When Slide index is 0
				if (slider.selectedIndex != 0) {
					prevButton.classList.remove('is-disabled');
				} else {
					prevButton.classList.add('is-disabled');
				}

				// Disable Next Button when Slide Index is maximum
				if (slider.selectedIndex == slider.slides.length - 1) {
					nextButton.classList.add('is-disabled');
				} else {
					nextButton.classList.remove('is-disabled');
				}
			});
		}
	};

	const __textAnimation = () => {
		const animateText = document.querySelector('.b-hero__animated-text');

		if (animateText) {
			var transitionSpeed = 750;

			for (let a = 1; a <= 20; a++) {
				setTimeout(function () {
					for (let i = 1; i < animated_words.length; i++) {
						setTimeout(function () {
							animateText.textContent = animated_words[i];
						}, i * transitionSpeed);
					}
				}, a * (animated_words.length - 1) * transitionSpeed);
			}
		}
	};

	const __addClassToHTML = () => {
		if (heroSlider || frontPageHero) {
			htmlRoot.classList.add('has-hero');

			if (heroSlider) {
				__slideContrast(slides, threshold, 0);
			} else if (frontPageHero) {
				htmlRoot.classList.add('hero-contrast');
			}
		}
	};

	const __sliderArrows = (slider) => {
		prevButton.addEventListener('click', function () {
			slider.previous();
		});

		nextButton.addEventListener('click', function () {
			slider.next();
		});
	};

	const __sliderButtons = (slider) => {
		sliderNav.forEach((val, index) => {
			val.addEventListener('click', function () {
				slider.select(index);
			});
		});
	};

	const __slideContrast = (background, threshold, i) => {
		// Vars
		const currentBackground = background[i],
			style = getComputedStyle(currentBackground),
			rgb = style.backgroundColor,
			filterComma = rgb.indexOf(',') > -1 ? ',' : ' ',
			rgbToArray = rgb.substr(4).split(')')[0].split(filterComma),
			rgbArr = rgbToArray.map((val) => parseInt(val)),
			contrast =
				(rgbArr[0] * 299 + rgbArr[1] * 587 + rgbArr[2] * 114) / 1000;

		if (contrast > threshold) {
			if (htmlRoot.classList.contains('hero-contrast')) {
				htmlRoot.classList.remove('hero-contrast');
			}
		} else {
			htmlRoot.classList.add('hero-contrast');
		}
	};

	const __videoLightbox = () => {
		slides.forEach((el) => {
			if (el.getAttribute('data-lightbox')) {
				el.addEventListener('click', function (e) {
					if (e.target.nodeName != 'A') {
						lity(el.getAttribute('data-lightbox'));
					}
				});
			}
		});
	};

	return {
		init: initialiseCompontent,
	};
})();
heroComponent.init();

const landingPageShowreelComponent = (() => {
	// Vars
	const showreels = document.querySelectorAll('.landing-page-showreel');

	const initialiseCompontent = () => {
		__videoLightbox();
	};

	const __videoLightbox = () => {
		showreels.forEach((el) => {
			if (el.getAttribute('data-lightbox')) {
				el.addEventListener('click', function (e) {
					if (e.target.nodeName != 'A') {
						lity(el.getAttribute('data-lightbox'));
					}
				});
			}
		});
	};

	return {
		init: initialiseCompontent,
	};
})();
landingPageShowreelComponent.init();

const burgerMenu = (() => {
	// Vars
	const burgerIcon = document.querySelector('.masthead__burger');
	const htmlRoot = document.getElementsByTagName('html')[0];
	const navMenu = [...document.querySelectorAll('.c-navigation__menu li')];

	const toggleMenu = () => {
		burgerIcon.addEventListener('click', function (e) {
			this.classList.toggle('is-active');
			htmlRoot.classList.toggle('menu-active');
			htmlRoot.classList.toggle('no-scroll');

			// for (let menu of navMenu) {
			// 	menu.classList.toggle('list-transition');
			// }

			navMenu.forEach(function (el) {
				el.classList.add('list-transition');
			});
		});
	};

	return {
		init: toggleMenu,
	};
})();

burgerMenu.init();

const introAnimation = (() => {
	const htmlRoot = document.getElementsByTagName('html')[0];
	const videoWrapper = document.querySelector('.v-animation');
	const video = document.querySelector('.v-animation video');

	if (videoWrapper) {
		if (sessionStorage.getItem('video') == '1') {
			videoWrapper.classList.add('is-hidden');
		} else {
			sessionStorage.setItem('video', '1');
			htmlRoot.classList.add('no-scroll');

			video.addEventListener('ended', () => {
				videoWrapper.classList.add('is-hidden');
				htmlRoot.classList.remove('no-scroll');
			});
		}
	}
})();

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

$(document).on('click', 'a[href^="#"]', function (event) {
	event.preventDefault();

	if ($('body').hasClass('page-template-landing-page')) {
		$('html, body').animate(
			{
				scrollTop: $($.attr(this, 'href')).offset().top - 137,
			},
			500
		);
	} else {
		$('html, body').animate(
			{
				scrollTop: $($.attr(this, 'href')).offset().top,
			},
			500
		);
	}
});

var scene = document.querySelector('.scene');
//var parallax = new Parallax(scene);

// Add Class to Even Text Block on Homepage
const homeTextBlock = document.querySelectorAll('.text-block--col-2');
homeTextBlock.forEach((val, i) => {
	if (i % 2 !== 0) {
		val.classList.add('text-block--col-2__even');
	}
});

// $(function () {
// 	window.requestAnimFrame =
// 		window.requestAnimationFrame ||
// 		window.webkitRequestAnimationFrame ||
// 		window.mozRequestAnimationFrame ||
// 		window.oRequestAnimationFrame ||
// 		window.msRequestAnimationFrame ||
// 		function (callback) {
// 			window.setTimeout(callback, 1e3 / 60);
// 		};
// 	// window.addEventListener('load', function(){
// 	var canvas = document.getElementById("myCanvas"),
// 		ctx = canvas.getContext("2d"),
// 		video = document.createElement("video"),
// 		mask = document.createElement("img"),
// 		videocanvas = document.createElement("canvas"),
// 		videoctx = videocanvas.getContext("2d"),
// 		maskdrawn;
// 	videocanvas.width = canvas.width;
// 	videocanvas.height = canvas.height;

// 	video.src = boucheVar.homepageVideo;
// 	video.loop = true;
// 	video.volume = 0;

// 	mask.src = boucheVar.homepageVideoOverlay;

// 	// mask.onload = function() {
// 	//   ctx.drawImage(mask, 0, 0, width, height);
// 	//   ctx.globalCompositeOperation = 'source-in';
// 	//   ctx.drawImage(img, 0, 0);

// 	//   img.src = imagecanvas.toDataURL();
// 	// }

// 	//document.body.appendChild(videocanvas)

// 	if (video.src === false || mask.src === false) {
// 		return false;
// 	}

// 	function drawMask() {
// 		// add the video frame image
// 		videoctx.drawImage(mask, 0, 0);

// 		var imgd = videoctx.getImageData(
// 				0,
// 				0,
// 				videocanvas.width,
// 				videocanvas.height
// 			),
// 			pix = imgd.data,
// 			i;

// 		for (var i = 0, n = pix.length; i < n; i += 4) {
// 			pix[i + 3] = pix[i];
// 		}

// 		videoctx.putImageData(imgd, 0, 0);
// 	}

// 	function draw() {
// 		ctx.globalCompositeOperation = "source-over";

// 		ctx.clearRect(0, 0, canvas.width, canvas.height);

// 		ctx.drawImage(video, 0, 0);

// 		ctx.globalCompositeOperation = "destination-in";

// 		drawMask();

// 		ctx.drawImage(videocanvas, 0, 0);

// 		window.requestAnimFrame(draw);
// 	}
// 	// video.onload = function() {
// 	// mask.onload = function() {
// 	window.requestAnimFrame(draw);

// 	draw();

// 	video.play();
// 	// }
// 	// }
// 	// }, false);
// });

jQueryBridget('isotope', Isotope, $);
jQueryBridget('imagesLoaded', imagesLoaded, $);

$(function () {
	const $grid = $('.post-type-archive-projects .b-projects__grid .row');
	$grid.imagesLoaded(function () {
		$grid.isotope({
			itemSelector: '.b-projects__grid-item',
			layoutMode: 'fitRows',
		});
	});

	$('.post-type-archive-projects .horizontal-list__link').on(
		'click',
		function (e) {
			e.preventDefault();
			let filter = $(this).attr('data-filter-type');
			$grid.isotope({
				filter: filter,
			});
		}
	);
});

// Modals.
function initSearchModal() {
	const modal = document.querySelector('#modal-search');
	const openModal = document.querySelectorAll('.open-search');
	const closeModal = document.querySelector('.close-button');

	openModal.forEach((button) => {
		button.addEventListener('click', () => {
			modal.showModal();
			setTimeout(() => {
				modal.find('#s').focus();
			}, 500);
		});
	});

	closeModal.addEventListener('click', () => {
		modal.close();
	});
}

initSearchModal();


//Set Cookies
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//Site-Notice
function siteNotice() {
	$('.site-notice__close').click(function() {
		setCookie('site-notice', siteNotice.cookie_value, 7);
		$('.site-notice').addClass('closed')
	});
}

siteNotice();

